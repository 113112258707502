import React, { createContext, useContext, useState } from "react";

const ContextOpen = createContext({});

const ContextProvider = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [message, setMessage] = useState([]);

  const changeMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <ContextOpen.Provider
      value={{ setOpenMenu, openMenu, changeMenu, message, setMessage }}
    >
      {children}
    </ContextOpen.Provider>
  );
};

export default ContextProvider;

export const useContextHook = () => {
  return useContext(ContextOpen);
};

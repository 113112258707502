import React from "react";
import { useParams } from "react-router-dom";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { Data, Title } from "../../components";
const Order = ({ title }) => {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/orders/${id}`);
  let dataAll = data?.data.data;
  console.log(dataAll);
  return (
    <section className="py-4">
      <Title title={title} />
      <Row
        justify="center"
        className="border border-Main rounded-2xl p-5 items-center"
      >
        <Col>
          {isLoading ? (
            <div className="rounded-full bg-slate-300 animate-pulse object-cover h-[120px] !w-[120px] mx-auto" />
          ) : (
            <img
              src={fileUrl + dataAll?.image}
              alt=""
              className="cursor-pointer rounded-full object-cover h-[120px] !w-[120px] mx-auto"
              onClick={clickZoomInImage}
            />
          )}
        </Col>
        <Col md={4} xs={6}>
          <Data load={isLoading} name="الرقم" text={dataAll?.id} />
        </Col>
        <Col md={4} xs={6}>
          <Data
            load={isLoading}
            name="الاسم"
            text={dataAll?.first_name + " " + dataAll?.last_name}
          />
        </Col>
        <Col md={4} xs={6}>
          <Data
            load={isLoading}
            name="رقم الهاتف"
            text={dataAll?.phone_number}
          />
        </Col>
        <Col md={4} xs={6}>
          <Data
            name="حالة الطلب"
            text={
              dataAll?.state === 0
                ? "بالإنتظار"
                : dataAll?.state === 1
                ? "اكتملت"
                : "مرفوضة"
            }
            className={`${
              dataAll?.state === 0
                ? "text-blue-600"
                : dataAll?.state === 1
                ? "text-green-600"
                : "text-red-600"
            }  font-semibold py-7`}
          />
        </Col>
        <Col md={4} xs={6}>
          <Data
            load={isLoading}
            name="التاريخ"
            text={dataAll?.created_at.substring(0, 10)}
          />
        </Col>
        <Col md={4} xs={6}>
          <Data load={isLoading} name="العنوان" text={dataAll?.address} />
        </Col>
        <Col md={4} xs={6}>
          <Data load={isLoading} name="المنطقة" text={dataAll?.region} />
        </Col>
        <Col md={4} xs={6}>
          <Data load={isLoading} name="الدولة" text={dataAll?.governorate} />
        </Col>
        <Col md={4} xs={6}>
          <Data
            load={isLoading}
            name="سعر التوصيل"
            text={"$" + " " + dataAll?.delivery_methods?.price}
          />
        </Col>
        <Col md={4} xs={6}>
          <Data
            load={isLoading}
            name="سعر الطلب"
            text={"$" + " " + dataAll?.total_price}
          />
        </Col>
        <Col md={4} xs={6}>
          <Data
            load={isLoading}
            name="السعر الكلي"
            text={"$" + " " + (dataAll?.total_price + dataAll?.total_price)}
          />
        </Col>
        <Col md={4} xs={6}>
          <Data
            load={isLoading}
            name="طريقة التوصيل"
            text={dataAll?.delivery_methods?.method?.ar}
          />
        </Col>
        <Col md={4} xs={6}>
          <Data
            load={isLoading}
            name="طريقة الدفع"
            text={dataAll?.payment_methods?.name?.ar}
          />
        </Col>
        <Col md={8} xs={6}>
          <Data
            load={isLoading}
            name="وصف طريقة الدفع"
            text={dataAll?.payment_methods?.description?.ar}
          />
        </Col>
      </Row>
      <Row className="!my-5">
        {dataAll?.items?.map((e) => (
          <Col md={4} xs={6}>
            <div className="border border-Secondary rounded-lg p-2 h-full">
              {isLoading ? (
                <div className="rounded-full bg-slate-300 animate-pulse object-cover h-[120px] !w-[120px] mx-auto" />
              ) : (
                <img
                  src={fileUrl + e.product?.image}
                  alt=""
                  className="cursor-pointer rounded-full object-cover h-[120px] !w-[120px] mx-auto"
                  onClick={clickZoomInImage}
                />
              )}
              <Data
                load={isLoading}
                name="اسم المنتج"
                text={e.product?.name?.ar}
              />
              <Data load={isLoading} name="الكمية" text={e.quantity} />
              <Data
                load={isLoading}
                name="وصف المنتج"
                text={e.product?.description?.ar}
              />
              <Data
                load={isLoading}
                name="سعر المنتج"
                text={"$" + " " + e.product?.price}
              />
              <Data
                load={isLoading}
                name="سعر خصم المنتج"
                text={"$" + " " + e.product?.discount}
              />
              <Data
                load={isLoading}
                name="السعر الكلي"
                text={
                  "$" +
                  " " +
                  (e.product?.price - e.product?.discount) * e.quantity
                }
              />
              <Data load={isLoading} name="اللون" text={e.color?.name} />
            </div>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default Order;

import React, { useState } from "react";
import { Link } from "react-router-dom";

const Add = ({ link }) => {
  const [add, setAdd] = useState(false);
  return (
    <Link to={link || ""}>
      <div
        onClick={() => setAdd(!add)}
        style={{ userSelect: "none" }}
        className={`${
          add ? "rotate-45 " : ""
        } flex z-10 active:-rotate-45 justify-center transition fixed bottom-10 left-5 font-bold text-5xl text-white cursor-pointer w-14 h-14 rounded-full bg-Secondary`}
      >
        +
      </div>
      <div
        className={`rounded-2xl fixed -bottom-full left-10 w-[200px] p-5 ${
          add ? "add" : ""
        }`}
      ></div>
    </Link>
  );
};

export default Add;

import parse from "html-react-parser";
import { clickZoomInImage } from "../../Tools/APIs/useMyAPI";
import Pagination from "../../Tools/Pagination";
import Delete from "../Operation/Delete";
import Show from "../Operation/Show";
import Update from "../Operation/Update";
import Cancel from "../Operation/Cancel";
import Accept from "../Operation/Accept";

const Table = ({
  thead,
  tbody,
  tData,
  show,
  funDelete,
  pageCount,
  edit,
  hide,
  showContent,
  isLoading,
  status,
  updateText,
  deleteText,
}) => {
  return (
    <div>
      <Pagination isLoading={isLoading} pageCount={pageCount}>
        <div className="mx-auto w-full py-4 overflow-x-auto h-full pb-5">
          <table className="min-w-[750px] mx-auto border overflow-hidden rounded-2xl h-full bg-white">
            <thead className="bg-Main text-white h-[50px] text-center">
              <tr>
                {thead?.map((e, i) => (
                  <td key={i} className="py-3 px-5 text-sm font-semibold">
                    {e}
                  </td>
                ))}
                {status && (
                  <td className={`py-3 px-5 text-sm font-semibold`}>
                    حالات الطلب
                  </td>
                )}
                <td className={`${hide ? "hidden" : "p-5 w-[200px]"}`}>
                  العمليات
                </td>
              </tr>
            </thead>
            <tbody className=" text-center">
              {tData &&
                tData?.map((e, i) => (
                  <tr key={i} className="border border-Main">
                    {tbody?.map((name, i) => (
                      <>
                        <td key={i} className={` py-1 px-2 text-gray-600`}>
                          {typeof e[name] === "string" &&
                          e[name].substring(0, 4) === "http" ? (
                            <img
                              src={e[name]}
                              alt=""
                              className="w-14 h-14 rounded-full bg-slate-200 mx-auto cursor-pointer"
                              onClick={clickZoomInImage}
                            />
                          ) : e[name] ? (
                            <div className={` min-w-[100px]`}>
                              {parse("" + e[name])}
                            </div>
                          ) : (
                            "___"
                          )}
                        </td>
                      </>
                    ))}
                    {status && (
                      <td
                        className={`${
                          e.status === "بالإنتظار"
                            ? "text-blue-600"
                            : e.status === "اكتملت"
                            ? "text-green-600"
                            : "text-red-600"
                        }  font-semibold py-7`}
                      >
                        {e.status === "بالإنتظار"
                          ? "بالإنتظار"
                          : e.status === "اكتملت"
                          ? "اكتملت"
                          : "مرفوضة"}
                      </td>
                    )}
                    <td
                      className={`${
                        hide ? "hidden" : "cursor-pointer relative px-2"
                      }`}
                    >
                      <div className="flex items-center gap-3 mx-auto w-[200px] justify-center">
                        {edit && (
                          <Update
                            edit={`/${edit}/${e.id}`}
                            updateText={updateText}
                          />
                        )}
                        {show && (
                          <Show show={`/${show}${e.id}`} text={showContent} />
                        )}
                        {funDelete && (
                          <Delete
                            deleteClick={() => funDelete(e)}
                            deleteText={deleteText}
                          />
                        )}
                        {e.status && e.status !== "اكتملت" ? (
                          <Accept id={e.id} />
                        ) : (
                          <div className={`w-10 h-10`} />
                        )}
                        {e.status && e.status === "بالإنتظار" ? (
                          <Cancel id={e.id} />
                        ) : (
                          <div className={`w-10 h-10`} />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Pagination>
    </div>
  );
};

export default Table;

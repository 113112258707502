import React from "react";

const P500 = () => {
  return (
    <h1 className="grid place-content-center h-[80vh] text-Main font-semibold ">
      500 | Internal Server Error
    </h1>
  );
};

export default P500;

import React from "react";

const Title = ({ title, className, load }) => {
  return (
    <div className=" mr-2">
      {load ? (
        <div className="bg-slate-300 animate-pulse w-28 mb-3 mx-5 rounded-sm h-5"></div>
      ) : (
        <li
          className={`text-gray-700 text-start mb-3 mr-3 ${className} text-xl font-bold`}
        >
          {title}
        </li>
      )}
    </div>
  );
};

export default Title;

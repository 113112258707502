import { useFilter } from "../../Tools/APIs/useMyAPI";
import MapSelect from "../Input/MapSelect";

const FilterOrderStatus = () => {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  let options = [
    { name: "الكل", value: "" },
    { name: "تمت", value: "1" },
    { name: "بالانتظار", value: "0" },
    { name: "الغيت", value: "2" },
  ];
  return (
    <>
      <MapSelect
        value={searchParams.get("state")}
        onChange={(e) => {
          if (e.target.value) {
            handleParamsClick("state", e.target.value);
          } else {
            handleParamsDelete("state");
          }
        }}
        title="الكل"
        options={options}
        nameOption="name"
        valueOption="value"
        className="!py-1 !border"
      />
    </>
  );
};

export default FilterOrderStatus;

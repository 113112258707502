import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { CardBox, Data, MainButton, Title } from "../../components";

const AboutUs = () => {
  const { data, isLoading, deleteItem } = useFETCH("admin/about-us");
  let dataAll = data?.data.data;
  return (
    <div className="py-5">
      <Title title="معلومات عنا" />
      <MainButton
        link="/About-Us/edit"
        name="تعديل المعلومات"
        className="!w-[170px] my-5"
      />
      {isLoading ? (
        <Row>
          <LoadingAboutUs />
          <LoadingAboutUs />
          <LoadingAboutUs />
          <LoadingAboutUs />
        </Row>
      ) : (
        ""
      )}
      <Row justify="center">
        {dataAll?.images.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox deleteClick={() => deleteItem(e)}>
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-[180px] h-[180px] mx-auto rounded-md object-contain"
              />
            </CardBox>
          </Col>
        ))}
      </Row>

      <Row>
        <Col className="mx-auto">
          <CardBox>
            <Data
              load={isLoading}
              name="الوصف بالعربي"
              text={true}
              parser={dataAll?.value?.ar}
            />
          </CardBox>
          <CardBox>
            <Data
              load={isLoading}
              name="الوصف بالإنكليزي"
              text={true}
              parser={dataAll?.value?.en}
            />
          </CardBox>
          <CardBox>
            <Data
              load={isLoading}
              name="الوصف بالكردي"
              text={true}
              parser={dataAll?.value?.kur}
            />
          </CardBox>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
const LoadingAboutUs = () => {
  return (
    <Col lg={3} md={4} xs={6}>
      <div className="w-full h-[230px] rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-full"></div>
      </div>
    </Col>
  );
};

import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import AddImage from "../../Tools/AddFile";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, TextArea } from "../../components";
import JoditEditor from "jodit-react";

const EditAboutUs = () => {
  const {
    handleChangeInput,
    handleChangeArrayImages,
    handleSubmit,
    viewImages,
    setViewImages,
    images,
    setImages,
    setFormData,
    formData,
    loading,
  } = usePOST({});
  const { data, isLoading } = useFETCH(`admin/about-us`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/about-us`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    setFormData({
      text_ar: dataOld?.value?.ar,
      text_en: dataOld?.value?.en,
      text_kur: dataOld?.value?.kur,
    });
  }, [dataOld]);

  return (
    <div>
      <Row justify={"center"}>
        <div className="border-2 border-Secondary rounded-2xl">
          <Row className="p-4 justify-center">
            <Col md={8} className="mx-auto">
              <AddImage
                type="IMAGES"
                name="images[]"
                onChange={handleChangeArrayImages}
              />
              <Row className="py-4">
                {viewImages["images[]"] &&
                  viewImages["images[]"]?.map((e, i) => (
                    <Col md={3} xs={4} sm={6} lg={24}>
                      <div className="relative">
                        <span
                          onClick={() => {
                            let total = [...viewImages["images[]"]];
                            let total2 = [...images["images[]"]];
                            total.splice(i, 1);
                            total2.splice(i, 1);
                            setViewImages({
                              ...viewImages["images[]"],
                              "images[]": total,
                            });
                            setImages({
                              ...images["images[]"],
                              "images[]": total2,
                            });
                          }}
                          className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                        >
                          X
                        </span>
                        <img src={e} alt="" className="w-full rounded-2xl" />
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col>
              <p className="mb-2 font-semibold">الوصف بالعربي</p>
              <JoditEditor
                value={formData?.text_ar}
                onChange={(e) => setFormData({ ...formData, text_ar: e })}
              />
            </Col>
            <Col>
              <p className="mb-2 font-semibold">الوصف بالإنكليزي</p>
              <JoditEditor
                value={formData?.text_en}
                onChange={(e) => setFormData({ ...formData, text_en: e })}
              />
            </Col>
            <Col>
              <p className="mb-2 font-semibold">الوصف بالكردي</p>
              <JoditEditor
                value={formData?.text_kur}
                onChange={(e) => setFormData({ ...formData, text_kur: e })}
              />
            </Col>
          </Row>
        </div>
        {loading || isLoading ? <Loading /> : ""}
        {/* <div className="text-red-500 font-semibold">{error}</div> */}
      </Row>
      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
};

export default EditAboutUs;

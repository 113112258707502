import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useFilter } from "../../Tools/APIs/useMyAPI";

function Search({ name }) {
  const { handleParamsClick } = useFilter();
  const [typingTimeout, setTypingTimeout] = useState("");
  return (
    <div className="">
      <div className="border border-Main flex items-center p-2 rounded-xl bg-white">
        <input
          placeholder="ابحث"
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            clearTimeout(typingTimeout);
            const newTypingTimeout = setTimeout(() => {
              handleParamsClick("search", value);
              if (name) {
                handleParamsClick("sort_by", name);
              }
            }, 1000);
            setTypingTimeout(newTypingTimeout);
          }}
        />
        <span>
          <IoSearchOutline size={20} />
        </span>
      </div>
    </div>
  );
}
export default Search;

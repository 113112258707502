import parse from "html-react-parser";

function Data({ name, text, load, className, parser }) {
  return (
    <div
      className={`font-bold mt-3 ${
        load ? "flex items-center" : ""
      } ${className}`}
    >
      <span className={`text-Main`}>{name} : </span>
      {load ? (
        <div className="bg-slate-300 animate-pulse w-3/5 mx-1 rounded-sm h-5"></div>
      ) : (
        <span className="font-normal flex-1">{text || "______"}</span>
      )}
      {parser && <span className="font-normal flex-1">{parse(parser)}</span>}
    </div>
  );
}
export default Data;

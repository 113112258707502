import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, Input, PasswordInput, Title } from "../../components";

const AddUser = () => {
  const { id } = useParams();
  const [message, setMessage] = useState(false);

  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data, isLoading } = useFETCH(id === "add" ? "" : `admin/users/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    if (formData?.passwordConfirmation === formData?.password) {
      handleSubmit(id === "add" ? "admin/users" : `admin/users/${id}`);
    } else {
      setMessage("كلمة المرور وتأكيد كلمة المرور غير متطابقتين");
    }
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        username: dataOld?.username,
        phone_number: dataOld?.phone_number,
        password: dataOld?.password,
      });
  }, [dataOld]);
  return (
    <div className="">
      <Row>
        <Col
          md={7}
          className="mx-auto border-2 border-Secondary rounded-2xl p-7 !px-4"
        >
          <Title
            title={id === "add" ? "إضافة مستخدم" : "تعديل المستخدم"}
            className="mb-14 mt-5"
          />
          <Input
            name="username"
            onChange={handleChangeInput}
            placeholder={"اسم المستخدم"}
            value={formData?.username}
            error={error?.username}
          />
          <Input
            name="phone_number"
            onChange={handleChangeInput}
            placeholder={"رقم الجوال"}
            value={formData?.phone_number}
            error={error?.phone_number}
          />
          <PasswordInput
            name="password"
            onChange={handleChangeInput}
            error={error?.password}
          />
          <PasswordInput
            name="passwordConfirmation"
            onChange={handleChangeInput}
            placeholder="تأكيد كلمة السر"
          />
          <div className="text-red-600">{message}</div>
          {loading || (id !== "add" && isLoading) ? <Loading /> : ""}
          <Back name="حفظ" onClick={handleSubmitMain} />
        </Col>
      </Row>
    </div>
  );
};

export default AddUser;

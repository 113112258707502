const Input = ({ type, name, value, onChange, placeholder, error }) => {
  return (
    <div className="py-3">
      <h1 className="text-start mb-2">{placeholder}</h1>
      <input
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="border-2 border-Main m-1 py-3 rounded-xl bg-white"
      />
      <div className="text-red-600">{error}</div>
    </div>
  );
};
export default Input;

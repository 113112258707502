import { FaUsers } from "react-icons/fa";
import { GiProfit } from "react-icons/gi";
import {
  MdIncompleteCircle,
  MdOutlineCancelPresentation,
  MdOutlinePendingActions,
} from "react-icons/md";
import { VscListOrdered } from "react-icons/vsc";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { MainButton, Statistics, Table, Title } from "../../components";
function Home() {
  const { data, isLoading } = useFETCH(`admin/statistics`);
  const { data: dataMinPrice } = useFETCH(`admin/orders/get/min-price`);
  const usersData = data?.data?.data?.last_ten_users?.map((e) => {
    return {
      id: e.id,
      username: e.username,
      phone_number: e.phone_number,
      created_at: e.created_at?.substring(0, 10),
    };
  });
  const dataAll = data?.data?.data;
  const Static = [
    {
      name: "عدد الطلبات المكتملة",
      number: dataAll?.completed_orders_count,
      icon: MdIncompleteCircle,
    },
    {
      name: "عدد الطلبات الملغية",
      number: dataAll?.rejected_orders_count,
      icon: MdOutlineCancelPresentation,
    },
    {
      name: "عدد الطلبات المعلقة",
      number: dataAll?.pending_orders_count,
      icon: MdOutlinePendingActions,
    },
    {
      name: "الأرباح",
      number: dataAll?.profits,
      icon: GiProfit,
    },
    {
      name: "عدد المستخدمين الكلي",
      number: dataAll?.users_count,
      icon: FaUsers,
    },
    {
      name: "الحد الأدنى للطلب",
      number: dataMinPrice?.data.data?.value,
      icon: VscListOrdered,
    },
  ];
  return (
    <div>
      <Row>
        {Static.map((e) => (
          <Col lg={3} xs={4} sm={6}>
            <Statistics
              name={e.name}
              number={e.number}
              Icon={e.icon}
              link={e.link}
            />
          </Col>
        ))}
      </Row>
      <MainButton
        link="/update-count"
        name="تعديل الحد الأدنى للطلب"
        className="!w-[220px] !bg-Main my-5"
      />
      <Title title="اخر عشرة قامو بالتسجيل" />
      <Table
        isLoading={isLoading}
        thead={["الرقم", "الاسم", "رقم الهاتف", "تاريخ الإنشاء"]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={usersData}
        tbody={["id", "username", "phone_number", "created_at"]}
        hide={true}
      />
    </div>
  );
}
export default Home;

import { useLocation } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, FilterSearch, Table, Title } from "../../components";

const Section = () => {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/sections${search}`,
    "admin/sections"
  );
  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.image,
      name_ar: e.name.ar,
      name_en: e.name.en,
      name_kur: e.name.kur,
    };
  });
  return (
    <div>
      <Title title="الأقسام" />
      <FilterSearch />
      <Table
        isLoading={isLoading}
        thead={[
          "الرقم",
          "صورة القسم",
          "الاسم بالعربي",
          "الاسم بالإنكليزي",
          "الاسم بالكردي",
        ]}
        tData={dataAll}
        tbody={["id", "image", "name_ar", "name_en", "name_kur"]}
        edit="Section"
        funDelete={deleteItem}
        show="Section/product/"
        showContent="عرض المنتجات الخاصة بالقسم"
        deleteText="حذف قسم"
        updateText="تعديل قسم"
      />
      <Add link="/Section/add" />
    </div>
  );
};

export default Section;

import React, { useState } from "react";
import { useContextHook } from "../../Context/ContextOPen";
import MainButton from "../Buttons/MainButton";
import IconHover from "../IconHover/IconHover";
import { Col, Row } from "../../Tools/Grid-system";
const Popup = ({
  description,
  onClick,
  onChange,
  yes,
  cancel,
  children,
  id,
  text,
  name,
  value,
  desClass,
}) => {
  const [sure, setSure] = useState(false);
  const { setOpenMenu } = useContextHook();
  return (
    <div>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg/black/60 z-50 flex justify-center items-center max-sm:w-full`}
          >
            <div className="bg-white !z-[999] rounded-3xl w-[500px] max-w-[500px] h-fit py-7 max-sm:mx-6">
              <p
                className={`font-semibold text-3xl text-center mb-9 ${desClass}`}
              >
                {description}
              </p>
              {onChange && (
                <input
                  placeholder="سبب الرفض"
                  type="text"
                  name={name}
                  value={value}
                  onChange={onChange}
                  className="border-2 outline-none px-4 py-3 rounded-xl border-red-800 w-[90%] mx-auto mb-6"
                />
              )}
              <Row justify="center">
                <Col sm={3}>
                  <MainButton
                    onClick={onClick}
                    name={yes || "نعم"}
                    className="!bg-Main hover:!bg-Main/60 transition-all"
                  />
                </Col>
                <Col sm={3}>
                  <MainButton
                    onClick={() => setSure(false)}
                    name={cancel || "إلغاء"}
                    className="!bg-Main hover:!bg-Main/60 transition-all"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}

      <div
        onClick={() => {
          setSure(true);
          setOpenMenu(false);
        }}
      >
        <IconHover id={id} text={text}>
          <div
            className={`w-10 h-10 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center
            `}
          >
            {children}
          </div>
        </IconHover>
      </div>
    </div>
  );
};

export default Popup;

import { useParams } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Back, Data, Title } from "../../components";
const ViewProduct = () => {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/products/${id}`);
  const dataAll = data?.data.data.data;
  return (
    <Container>
      <Title
        title={"الأقسام" + " » " + "المنتجات" + " » " + dataAll?.name?.en}
      />
      <Row justify="center">
        {isLoading ? (
          <Row>
            <Loading />
            <Loading />
            <Loading />
          </Row>
        ) : (
          ""
        )}
        <Swiper
          spaceBetween={20}
          className="mySwiper"
          // modules={[Navigation]}
          // navigation={true}
          style={{ direction: "ltr" }}
          slidesPerView={5}
          breakpoints={{
            1500: {
              slidesPerView: 5.1,
            },
            1024: {
              slidesPerView: 4.1,
            },
            500: {
              slidesPerView: 3.1,
            },
            0: {
              slidesPerView: 2.1,
            },
          }}
        >
          {dataAll?.images?.map((e) => (
            <SwiperSlide key={e.id}>
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-[160px] max-sm:h-[140px] object-contain bg-slate-200 rounded-xl p-2"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Row
          justify="center"
          className="items-center border-2 border-Main rounded-2xl !p-3 !my-5"
        >
          <Col lg={4} md={6} className="space-y-5">
            <Data
              load={isLoading}
              name="الاسم بالإنكليزي"
              text={dataAll?.name?.en}
            />
          </Col>
          <Col lg={4} md={6} className="space-y-5">
            <Data
              load={isLoading}
              name="الاسم بالعربي"
              text={dataAll?.name?.ar}
            />
          </Col>
          <Col lg={4} md={6} className="space-y-5">
            <Data
              load={isLoading}
              name="الاسم بالكردي"
              text={dataAll?.name?.kur}
            />
          </Col>
          <Col lg={4} md={6} className="space-y-5">
            <Data load={isLoading} name="سعر الخصم" text={dataAll?.discount} />
          </Col>
          <Col lg={4} md={6} className="space-y-5">
            <Data load={isLoading} name="السعر الكلي" text={dataAll?.price} />
          </Col>
          <Col lg={4} md={6} className="space-y-5">
            <Data load={isLoading} name="سعر الكمية" text={dataAll?.quantity} />
          </Col>
          <Col lg={4} md={6} className="space-y-5">
            <Data
              load={isLoading}
              name="الوصف بالإنكليزي"
              text={dataAll?.description?.en}
            />
          </Col>
          <Col lg={4} md={6} className="space-y-5">
            <Data
              load={isLoading}
              name="الوصف بالعربي"
              text={dataAll?.description?.ar}
            />
          </Col>
          <Col lg={4} md={6} className="space-y-5">
            <Data
              load={isLoading}
              name="الوصف بالكردي"
              text={dataAll?.description?.kur}
            />
          </Col>
          <Col lg={4} md={6} className="space-y-5">
            <Data load={isLoading} name="الكمية" text={dataAll?.quantity} />
          </Col>
        </Row>
        {/* <Row justify="center" className="items-center">
          <h2 className="text-2xl font-bold text-center my-5">Rating</h2>
          <Col lg={4} md={6} className="space-y-5">
            <div className="flex justify-center items-center gap-3">
              <img
                src={test}
                alt=""
                className="w-20 h-20 bg-yellow-400 rounded-full overflow-hidden"
              />
              <div className="flex flex-col items-center justify-center ">
                <p className="font-bold text-xl">Dianne Russell</p>
                <ReactStars
                  count={5}
                  value={5}
                  size={24}
                  isHalf={true}
                  edit={false}
                  activeColor="#ffd700"
                />
              </div>
            </div>
          </Col>
        </Row> */}
      </Row>
      <Back name="تعديل" link={`/Section/${id}/Product/${dataAll?.id}`} />
    </Container>
  );
};

export default ViewProduct;
const Loading = () => {
  return (
    <Col lg={4} xs={6}>
      <div className="w-full h-[230px] rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-full"></div>
      </div>
    </Col>
  );
};

import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { Add, CardBox, Title } from "../../components";

const ImageSlider = () => {
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/sliders`,
    `admin/sliders`
  );
  return (
    <div>
      <Title title="الصور" />
      {isLoading ? (
        <Row>
          <Loading />
          <Loading />
          <Loading />
          <Loading />
        </Row>
      ) : (
        ""
      )}
      <Row>
        {data?.data.data.images.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox deleteClick={() => deleteItem(e)}>
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-[180px] max-sm:h-[150px] object-contain mx-auto bg-slate-100 cursor-pointer"
                onClick={clickZoomInImage}
              />
            </CardBox>
          </Col>
        ))}
      </Row>
      <Add link="/home/add-image-slider" />
    </div>
  );
};

export default ImageSlider;
const Loading = () => {
  return (
    <Col lg={3} md={4} xs={6}>
      <div className="w-full h-[230px] rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-full"></div>
      </div>
    </Col>
  );
};

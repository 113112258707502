import { useFilter } from "../../Tools/APIs/useMyAPI";
import MapSelect from "../Input/MapSelect";
import Search from "../Input/Search";
const FilterSearch = ({ select }) => {
  const { searchParams, handleParamsClick } = useFilter();
  let options = [
    { name: "الرقم", value: "id" },
    { name: "التقييم", value: "rating" },
  ];
  return (
    <div className="flex items-center flex-wrap gap-3 mb-5">
      <Search />
      {select && (
        <MapSelect
          value={searchParams.get("type")}
          onChange={(e) => handleParamsClick("type", e.target.value)}
          title="فلتر"
          All={true}
          options={options}
          nameOption="name"
          valueOption="value"
          className="!py-2 !border"
        />
      )}
    </div>
  );
};

export default FilterSearch;

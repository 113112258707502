import React from "react";
import { Link } from "react-router-dom";

const MainButton = ({ name, link, onClick, className }) => {
  return (
    <Link to={`${link || ""}`}>
      <div
        className={`bg-Secondary rounded-xl hover:bg-opacity-70 text-center font-semibold text-white cursor-pointer w-full px-5 py-3 ${className}`}
        onClick={onClick}
      >
        {name}
      </div>
    </Link>
  );
};

export default MainButton;

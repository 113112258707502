import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Back, Input, Title } from "../../components";
import Loading from "../../Tools/Loading";
import { Col, Row } from "../../Tools/Grid-system";

function UpdateContact() {
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
  } = usePOST({});
  const { data, isLoading } = useFETCH(`admin/contacts`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/contacts`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    setFormData({
      instagram: dataOld?.instagram?.value?.link,
      facebook: dataOld?.facebook?.value?.link,
      email: dataOld?.email?.value?.link,
    });
  }, [dataOld]);
  return (
    <div>
      <Title title="تعديل تواصل معنا" />
      <div className="border-2 border-Secondary rounded-2xl">
        <Row className="p-4 justify-center">
          <Col md={6}>
            <Input
              name="instagram"
              onChange={handleChangeInput}
              placeholder="الإنستاغرام"
              value={formData?.instagram}
            />
          </Col>
          <Col md={6}>
            <Input
              name="facebook"
              onChange={handleChangeInput}
              placeholder="الفيس بوك"
              value={formData?.facebook}
            />
          </Col>
          <Col md={6}>
            <Input
              name="email"
              onChange={handleChangeInput}
              placeholder="الإيميل"
              value={formData?.email}
            />
          </Col>
        </Row>
      </div>
      {loading || isLoading ? <Loading /> : ""}
      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
}
export default UpdateContact;

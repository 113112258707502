import { FaFacebook, FaInstagram } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { MainButton, Title } from "../../components";

const ContactUs = () => {
  const { data, isLoading } = useFETCH(`admin/contacts`);
  let dataAll = data?.data.data;
  let ContactUs = [
    {
      icon: FaInstagram,
      color: "#CB3878",
      name: dataAll?.instagram?.value?.link,
    },
    {
      icon: FaFacebook,
      color: "#3B5998",
      name: dataAll?.facebook?.value?.link,
    },
    {
      icon: MdOutlineMailOutline,
      color: "#707070",
      name: dataAll?.email?.value?.link,
    },
  ];
  return (
    <div>
      <Title title="تواصل معنا" />
      {isLoading ? <Loading /> : ""}
      <Row justify="between">
        {ContactUs.map((e, i) => (
          <Col md={6} key={i}>
            <div className="flex items-center justify-between border-[3px] border-Main rounded-xl shadow-md font-semibold text-xl py-5 px-3 bg-white">
              <e.icon size={60} color={e.color} />
              <p className="font-semibold px-2 text-xl break-all">{e.name}</p>
            </div>
          </Col>
        ))}
      </Row>
      <div className="fixed bottom-10 left-10">
        <MainButton
          link="/Contact-Us/edit"
          name="تعديل"
          className="px-7 py-2"
        />
      </div>
    </div>
  );
};

export default ContactUs;

function TextArea({ title, name, value, onChange, className, error }) {
  return (
    <div className="my-3 px-1">
      <h1 className="text-start mb-2">{title}</h1>
      <textarea
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        className={`border-2 border-Main outline-none m-1 py-3 rounded-xl block w-full px-3 h-[150px] ${className}`}
      />
      <div className="text-red-600">{error}</div>
    </div>
  );
}
export default TextArea;

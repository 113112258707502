import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";

function PasswordInput({ onChange, value, name, placeholder, error }) {
  const [biShow, setBiShow] = useState(false);
  return (
    <div className="">
      <h1 className="text-start mb-2">{placeholder || "كلمة السر"}</h1>
      <div className="relative">
        <input
          type={!biShow ? "password" : "text"}
          onChange={onChange}
          value={value}
          name={name}
          autoComplete="new-password"
          placeholder={placeholder || "كلمة السر"}
          className="w-full border-2 border-Main my-1 py-3 rounded-xl"
        />
        {biShow ? (
          <BiShow
            onClick={() => setBiShow(false)}
            size={25}
            className="absolute left-3 top-4 cursor-pointer"
          />
        ) : (
          <BiHide
            onClick={() => setBiShow(true)}
            size={25}
            className="absolute left-3 top-4 cursor-pointer"
          />
        )}
      </div>
      <div className="text-red-600">{error}</div>
    </div>
  );
}
export default PasswordInput;

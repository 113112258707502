import { useLocation, useParams } from "react-router-dom";
import ReactStars from "react-stars";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import {
  Add,
  CardBox,
  FilterSearch,
  LoadingProduct,
  Title,
} from "../../components";
const Products = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/sections/${id}${search}`,
    `admin/products`
  );
  let dataAll = data?.data.data;
  return (
    <div>
      <Title
        load={isLoading}
        title={"الأقسام" + " » " + dataAll?.section_name}
      />
      <FilterSearch />
      {isLoading && (
        <Row>
          <Col lg={3} md={4} xs={6}>
            <LoadingProduct />
          </Col>
          <Col lg={3} md={4} xs={6}>
            <LoadingProduct />
          </Col>
          <Col lg={3} md={4} xs={6}>
            <LoadingProduct />
          </Col>
          <Col lg={3} md={4} xs={6}>
            <LoadingProduct />
          </Col>
        </Row>
      )}
      <Row justify={"center"}>
        {data?.data.data.data?.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox
              edit={`/Section/${id}/Product/${e.id}`}
              deleteClick={() => deleteItem(e)}
              show={`/Section/product/view/${e.id}`}
              text={"عرض تفاصيل المنتج"}
              className="h-full"
              deleteText="حذف المنتج"
              updateText="تعديل المنتج"
            >
              <Swiper
                pagination={true}
                modules={[Pagination]}
                className="mySwiper"
                style={{ direction: "ltr" }}
              >
                {e.images?.map((e) => (
                  <SwiperSlide key={e.id}>
                    <img
                      src={fileUrl + e.image}
                      alt=""
                      className="w-full h-[160px] object-contain bg-slate-200"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <h1 className="font-bold text-xl my-3">{e.name?.ar}</h1>
              <div className="flex justify-between items-center">
                <div style={{ direction: "ltr" }}>
                  <ReactStars
                    count={5}
                    value={e.total_rate}
                    size={24}
                    isHalf={true}
                    edit={false}
                    activeColor="#ffd700"
                  />
                </div>
                <div>
                  <p className="text-gray-400 text-sm">الكمية : {e.quantity}</p>
                  <p className="text-gray-400 text-sm">
                    عدد النجمات : {e.total_rate}
                  </p>
                </div>
              </div>
              <p className="text-gray-400 text-sm">
                عدد المُقييمين : {e.rates.length}
              </p>
              <p className="text-gray-500">{e.description?.ar}</p>
              <div className="flex justify-between items-center">
                <p className="text-lg font-bold">$ {e.price}</p>
                {e.discount && (
                  <p className="line-through text-gray-400">
                    {e.discount + " " + "$"}
                  </p>
                )}
              </div>
            </CardBox>
          </Col>
        ))}
      </Row>
      <Add link={`/Section/${id}/Product/add`} />
    </div>
  );
};

export default Products;

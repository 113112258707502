import { RiEdit2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import IconHover from "../IconHover/IconHover";

function Update({ edit, updateText }) {
  return (
    <>
      <IconHover text={updateText || "تعديل"} id="update">
        <div
          className={`w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
        >
          <Link to={edit || ""}>
            <RiEdit2Fill size={27} className="cursor-pointer text-green-600" />
          </Link>
        </div>
      </IconHover>
    </>
  );
}
export default Update;

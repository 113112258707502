import Delete from "../Operation/Delete";
import Show from "../Operation/Show";
import Update from "../Operation/Update";
const CardBox = ({
  children,
  deleteClick,
  edit,
  className,
  show,
  text,
  updateText,
  deleteText,
}) => {
  return (
    <>
      <div
        className={`border-2 border-Secondary rounded-2xl p-3 w-full my-5 h-fit ${className}`}
      >
        <div className="flex justify-end gap-1 mb-1">
          {show && <Show show={show} text={text} />}
          {edit && <Update edit={edit} updateText={updateText} />}
          {deleteClick && (
            <Delete deleteClick={deleteClick} deleteText={deleteText} />
          )}
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};
export default CardBox;

import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, Table, Title } from "../../components";

const DeliveryMethods = () => {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/delivery-methods${search}`
  );
  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e.id,
      method: e.method?.ar,
      prise: e.price + " $",
    };
  });
  return (
    <div>
      <Title title="طرق التوصيل" />
      <Table
        isLoading={isLoading}
        thead={["الرقم", "الاسم", "السعر"]}
        tData={dataAll}
        tbody={["id", "method", "prise"]}
        edit="delivery-methods"
        funDelete={deleteItem}
      />
      <Add link="/delivery-methods/add" />
    </div>
  );
};

export default DeliveryMethods;

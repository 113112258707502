import { Route, Routes } from "react-router-dom";
import { Navbar, SideBar } from "./Layout";
import { RequireAuth, useFETCH } from "./Tools/APIs/useMyAPI";
import { Container } from "./Tools/Grid-system";
import Logo from "./images/logo.png";
import * as error from "./Tools/Error";
import * as page from "./pages/index";

const App = () => {
  const { data } = useFETCH(
    localStorage.getItem("token") ? "admin/check/auth" : ""
  );
  return (
    <>
      <div className="relative flex">
        <SideBar />
        <div className="container mx-auto relative">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login">
                <Route index element={<page.Login />} />
                <Route
                  path="forget-password"
                  element={<page.ForgetPassword />}
                />
                <Route path="set-password" element={<page.SetPassword />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="*" element={<error.P404 />} />
                <Route path="403" element={<error.P403 />} />
                <Route path="500" element={<error.P500 />} />
                <Route path="/" element={<page.Home />} />
                <Route path="home">
                  <Route index element={<page.ImageSlider />} />
                  <Route
                    path="add-image-slider"
                    element={<page.AddImageSlider />}
                  />
                </Route>
                <Route path="About-Us">
                  <Route index element={<page.AboutUs />} />
                  <Route path="edit" element={<page.EditAboutUs />} />
                </Route>
                <Route path="Contact-Us">
                  <Route index element={<page.ContactUs />} />
                  <Route path="Edit" element={<page.UpdateContact />} />
                </Route>
                <Route path="update-count" element={<page.UpdateCount />} />
                <Route path="users">
                  <Route index element={<page.Users />} />
                  <Route path=":id" element={<page.AddUser />} />
                  <Route
                    path="orders"
                    element={
                      <page.Orders
                        show="users/orders/"
                        title={"المستخدمين " + " » " + "الطلبات"}
                      />
                    }
                  />
                  <Route
                    path="orders/:id"
                    element={
                      <page.Order
                        title={
                          "المستخدمين" +
                          " » " +
                          "الطلبات" +
                          " » " +
                          "عناصر الطلب"
                        }
                      />
                    }
                  />
                </Route>
                <Route path="message" element={<page.Messages />} />
                <Route path="Orders">
                  <Route index element={<page.Orders show="Orders/" />} />
                  <Route
                    path=":id"
                    element={
                      <page.Order title={"الطلبات" + " » " + "عناصر الطلب"} />
                    }
                  />
                </Route>
                <Route path="colors">
                  <Route index element={<page.Colors />} />
                  <Route path=":id" element={<page.AddColors />} />
                </Route>
                <Route path="payment-methods">
                  <Route index element={<page.PaymentMethods />} />
                  <Route path=":id" element={<page.AddPaymentMethods />} />
                </Route>
                <Route path="delivery-methods">
                  <Route index element={<page.DeliveryMethods />} />
                  <Route path=":id" element={<page.AddDeliveryMethods />} />
                </Route>
                <Route path="Section">
                  <Route index element={<page.Section />} />
                  <Route path=":id" element={<page.AddSection />} />
                  <Route path="Product/:id" element={<page.Products />} />
                  <Route
                    path=":id/Product/:idd"
                    element={<page.AddProduct />}
                  />
                  <Route
                    path="Product/view/:id"
                    element={<page.ViewProduct />}
                  />
                </Route>
                <Route
                  path="Change-Password"
                  element={<page.ChangePassword />}
                />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;

function TableLoading() {
  return (
    <div>
      <div className="flex items-center w-full h-fit p-1 bg-white">
        <div className="bg-slate-300 animate-pulse w-[50px] max-sm:w-[30px] max-sm:h-[30px] h-[50px] rounded-full"></div>
        <div className="bg-slate-300 animate-pulse w-[60px] h-5 my-2 rounded-md mx-auto "></div>
        <div className="bg-slate-300 animate-pulse w-[80px] h-5 my-2 rounded-md mx-auto "></div>
        <div className="bg-slate-300 animate-pulse w-[40px] h-5 my-2 rounded-md mx-auto "></div>
        <div className="bg-slate-300 animate-pulse w-[80px] max-sm:hidden h-5 my-2 rounded-md mx-auto "></div>
        <div className="bg-slate-300 animate-pulse w-[100px] max-sm:hidden h-5 my-2 rounded-md mx-auto "></div>
        <div className="bg-slate-300 animate-pulse w-[40px] h-5 my-2 rounded-md mx-auto "></div>
      </div>
    </div>
  );
}
export default TableLoading;

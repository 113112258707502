import { VscWorkspaceUntrusted } from "react-icons/vsc";
import Popup from "../Popup/Popup";
import { usePOST } from "../../Tools/APIs/useMyAPI";
const Cancel = ({ id }) => {
  const { handleSubmit } = usePOST({});
  const handleClick = () => {
    handleSubmit(`admin/orders/${id}/state?state=` + 2, "", "", true);
  };
  return (
    <div>
      <Popup
        text="إلغاء الطلب"
        id="cancel"
        description="هل انت متأكد من إلغاء هذا الطلب ؟"
        onClick={handleClick}
        desClass="text-red-500"
      >
        <VscWorkspaceUntrusted size={25} className="text-red-600" />
      </Popup>
    </div>
  );
};

export default Cancel;

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, Input, Title } from "../../components";

const AddDeliveryMethods = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data } = useFETCH(id === "add" ? "" : `admin/delivery-methods/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? "admin/delivery-methods" : `admin/delivery-methods/${id}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        method_ar: dataOld?.method?.ar,
        method_en: dataOld?.method?.en,
        method_kur: dataOld?.method?.kur,
        price: dataOld?.price,
      });
  }, [dataOld]);
  const dataInput = [
    {
      type: "string",
      name: "method_en",
      placeholder: "الاسم بالإنكليزي",
    },
    {
      type: "string",
      name: "method_ar",
      placeholder: "الاسم بالعربي",
    },
    {
      type: "string",
      name: "method_kur",
      placeholder: "الاسم بالكردي",
    },
    {
      type: "number",
      name: "price",
      placeholder: "السعر",
    },
  ];
  return (
    <div>
      <Title
        title={id === "add" ? "إضافة طريقة توصيل" : "تعديل طريقة التوصيل"}
      />
      <div className="border-2 border-Secondary rounded-2xl">
        <Row className="p-4 justify-center">
          {dataInput.map((e, i) => {
            return (
              <Col md={6} key={i}>
                <Input
                  type={e.type}
                  name={e.name}
                  placeholder={e.placeholder}
                  value={formData?.[e.name]}
                  error={error?.[e.name]}
                  onChange={handleChangeInput}
                />
              </Col>
            );
          })}
        </Row>
      </div>
      {loading ? <Loading /> : ""}
      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
};

export default AddDeliveryMethods;

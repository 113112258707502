
function LoadingProduct() {
  return (
    <div>
        <div className="w-full h-[360px]  rounded-2xl p-5 bg-white border">
          <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
          <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto "></div>
          <div className="bg-slate-300 animate-pulse w-full h-5 my-2 mx-auto "></div>
          <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto "></div>
        </div>
    </div>
  );
}
export default LoadingProduct

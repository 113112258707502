import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import AddImage from "../../Tools/AddFile";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, Input, TextArea, Title } from "../../components";

const AddPaymentMethods = () => {
  const { id } = useParams({});
  const {
    handleChangeInput,
    handleSubmit,
    viewFile,
    setViewFile,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data, isLoading } = useFETCH(
    id === "add" ? "" : `admin/payment-methods/${id}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? "admin/payment-methods" : `admin/payment-methods/${id}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        name_en: dataOld?.name?.en,
        name_ar: dataOld?.name?.ar,
        name_kur: dataOld?.name?.kur,
        description_en: dataOld?.description?.kur,
        description_ar: dataOld?.description?.kur,
        description_kur: dataOld?.description?.kur,
      });
  }, [dataOld, viewFile]);

  const dataTextarea = [
    { name: "description_en", placeholder: "الوصف بالإنكليزي" },
    { name: "description_ar", placeholder: "الوصف بالعربي" },
    { name: "description_kur", placeholder: "الوصف بالكردي" },
  ];
  return (
    <div>
      <Title title={id === "add" ? "إضاف طريقة دفع" : "تعديل طريقة الدفع"} />
      <Row className="p-4 border-2 border-Secondary rounded-2xl justify-center">
        <Col md={6}>
          <Input
            name="name_en"
            onChange={handleChangeInput}
            placeholder="الاسم بالإنكليزي"
            value={formData?.name_en}
            error={error?.name_en}
          />
        </Col>
        <Col md={6}>
          <Input
            name="name_ar"
            onChange={handleChangeInput}
            placeholder="الاسم بالعربي"
            value={formData?.name_ar}
            error={error?.name_ar}
          />
        </Col>
        <Col md={6}>
          <Input
            name="name_kur"
            onChange={handleChangeInput}
            placeholder="الاسم بالكردي"
            value={formData?.name_kur}
            error={error?.name_kur}
          />
        </Col>
        <Col md={6}>
          <AddImage
            name="image"
            error={error?.image}
            oldImage={dataOld?.image && fileUrl + dataOld?.image}
            newImage={viewFile}
            onChange={handleChangeInput}
            title="إضف صورة طريقة الدفع"
            clickDeleteImage={() => {
              setViewFile("");
              setFormData({ ...formData, images: "" });
            }}
          />
        </Col>
        {dataTextarea.map((e, i) => {
          return (
            <Col md={6} key={i}>
              <TextArea
                type={e.type}
                name={e.name}
                title={e.placeholder}
                value={formData?.[e.name]}
                error={error?.[e.name]}
                onChange={handleChangeInput}
              />
            </Col>
          );
        })}
      </Row>
      {loading || (id !== "add" && isLoading) ? <Loading /> : ""}
      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
};

export default AddPaymentMethods;

import React from "react";
import { Link } from "react-router-dom";
import MainButton from "./MainButton";

const Back = ({ name, onClick, link }) => {
  return (
    <div className="fixed bottom-3 left-5 flex gap-2">
        <MainButton
          link={link || ""}
          onClick={onClick}
          name={name}
          className={`bg-Secondary px-4 text-white hover:bg-opacity-75 ${
            name ? "" : "hidden"
          }`}
        />
      <MainButton
        className="py-2 px-5"
        name="تراجع"
        onClick={() => window.history.go(-1)}
      />
    </div>
  );
};

export default Back;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import AddImage from "../../Tools/AddFile";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, Checkbox, Input, TextArea, Title } from "../../components";

const AddProduct = () => {
  const { id, idd } = useParams();
  const [deleteImgs, setDeleteImgs] = useState(false);
  const {
    handleChangeInput,
    handleChangeArrayImages,
    handleCheckedArray,
    handleSubmit,
    setViewImages,
    setImages,
    setCheckArray,
    setFormData,
    viewImages,
    images,
    formData,
    loading,
    error,
  } = usePOST({ is_special: 0, "colors[]": [] });
  const { data, isLoading } = useFETCH(
    idd === "add" ? "" : `admin/products/${idd}`
  );
  const { data: dataColor } = useFETCH(`admin/colors`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      idd === "add" ? `admin/sections/${id}/products` : `admin/products/${idd}`
    );
  };
  let dataOld = data?.data.data.data;
  useEffect(() => {
    idd !== "add" &&
      setFormData({
        ...formData,
        name_ar: dataOld?.name?.ar,
        name_en: dataOld?.name?.en,
        name_kur: dataOld?.name?.kur,
        description_ar: dataOld?.description?.ar,
        description_en: dataOld?.description?.en,
        description_kur: dataOld?.description?.kur,
        discount: dataOld?.discount,
        price: dataOld?.price,
        is_special: dataOld?.is_special,
        quantity: dataOld?.quantity,
      });
    setSelectColor(
      data?.data.data?.colors?.map((e) => {
        return { value: e.id, label: e.name };
      })
    );
    setCheckArray({
      "colors[]": selectColor?.map((e) => [e.value]),
    });
  }, [dataOld]);
  const [selectColor, setSelectColor] = useState([]);
  const ColorOption = dataColor?.data?.data?.map((e) => {
    return { value: e.id, label: e.name };
  });
  const dataInput = [
    {
      type: "string",
      name: "name_en",
      placeholder: "الاسم بالإنكليزي",
    },
    {
      type: "string",
      name: "name_ar",
      placeholder: "الاسم بالعربي",
    },
    {
      type: "string",
      name: "name_kur",
      placeholder: "الاسم بالكردي",
    },
    {
      type: "number",
      name: "discount",
      placeholder: "الخصم",
    },
    {
      type: "number",
      name: "price",
      placeholder: "السعر",
    },
    {
      type: "number",
      name: "quantity",
      placeholder: "الكمية",
    },
  ];
  return (
    <div>
      <Title title={idd === "add" ? "إضافة منتج" : "تعديل المنتج"} />
      <div className="border-2 border-Secondary rounded-2xl">
        <Row className="p-4 justify-center">
          {dataInput.map((e, i) => {
            return (
              <Col md={6} key={i}>
                <Input
                  type={e.type}
                  name={e.name}
                  placeholder={e.placeholder}
                  value={formData?.[e.name]}
                  error={error?.[e.name]}
                  onChange={handleChangeInput}
                />
              </Col>
            );
          })}
          <Col md={7}>
            <h1 className="text-start mb-2">اختر اللون</h1>
            <Select
              closeMenuOnSelect={false}
              isMulti
              name="colors[]"
              value={selectColor}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions?.map((option) => [
                  option.value,
                ]);
                setSelectColor(selectedOptions);
                setCheckArray({ "colors[]": selectedValues });
              }}
              options={ColorOption}
            />
            <div className="text-red-500 font-semibold">{error?.colors}</div>
          </Col>
          <Col md={6}>
            <TextArea
              name="description_en"
              onChange={handleChangeInput}
              title="الوصف بالإنكليزي"
              value={formData?.description_en}
              error={error?.description_en}
            />
          </Col>
          <Col md={6}>
            <TextArea
              name="description_ar"
              onChange={handleChangeInput}
              title="الوصف بالعربي"
              value={formData?.description_ar}
              error={error?.description_ar}
            />
          </Col>
          <Col md={6}>
            <TextArea
              name="description_kur"
              onChange={handleChangeInput}
              title="الوصف بالكردي"
              value={formData?.description_kur}
              error={error?.description_kur}
            />
          </Col>
          <Col md={8} className="mx-auto">
            <AddImage
              type="IMAGES"
              name="images[]"
              onChange={handleChangeArrayImages}
              error={error?.images}
            />
            <Row className="py-4">
              {viewImages["images[]"] &&
                viewImages["images[]"]?.map((e, i) => (
                  <Col md={3} xs={4} sm={6} lg={24}>
                    <div className="relative">
                      <span
                        onClick={() => {
                          let total = [...viewImages["images[]"]];
                          let total2 = [...images["images[]"]];
                          total.splice(i, 1);
                          total2.splice(i, 1);
                          setViewImages({
                            ...viewImages["images[]"],
                            "images[]": total,
                          });
                          setImages({
                            ...images["images[]"],
                            "images[]": total2,
                          });
                        }}
                        className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                      >
                        X
                      </span>
                      <img src={e} alt="" className="w-full rounded-2xl" />
                    </div>
                  </Col>
                ))}
            </Row>
            {idd !== "add" && (
              <div>
                <div
                  onClick={() => setDeleteImgs(!deleteImgs)}
                  className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg text-lg text-white font-semibold bg-Main my-2 ml-auto "
                >
                  حذف الصور
                </div>
                <Row>
                  {dataOld?.images?.map((e) => (
                    <Col key={e.id} md={4} sm={6} className="relative">
                      {deleteImgs && (
                        <input
                          type="checkbox"
                          value={e.id}
                          name="images_to_delete[]"
                          onChange={handleCheckedArray}
                          className="absolute -top-2 left-1 h-5 w-5"
                        />
                      )}
                      <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                        <img
                          src={fileUrl + e.image}
                          alt=""
                          className="w-full h-full object-contain rounded-2xl"
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </Col>
          <Col col={3}>
            <Checkbox
              name="is_special"
              checked={formData.is_special}
              onChange={handleChangeInput}
              title="مميز"
            />
          </Col>
        </Row>
      </div>

      {loading || (idd !== "add" && isLoading) ? <Loading /> : ""}
      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
};

export default AddProduct;

import React from "react";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { CardBox, Data, Title } from "../../components";

const Messages = () => {
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/messages`,
    "admin/messages"
  );
  return (
    <>
      <Title title="الرسائل" />
      {isLoading ? (
        <Row>
          <Loading />
          <Loading />
          <Loading />
          <Loading />
          <Loading />
          <Loading />
        </Row>
      ) : (
        ""
      )}
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          {data?.data.data.data.map((e) => (
            <Col lg={4} md={6} key={e.id}>
              <CardBox showEdit={true} deleteClick={() => deleteItem(e)}>
                <Data load={isLoading} name="الاسم" text={e.username} />
                <Data load={isLoading} name="الإيميل" text={e.email} />
                <Data load={isLoading} name="الرسالة" text={e.message} />
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>
    </>
  );
};

export default Messages;
const Loading = () => {
  return (
    <Col lg={4} xs={6}>
      <div className="w-full h-fit rounded-2xl p-5 bg-white border pt-8">
        <div className="bg-slate-300 animate-pulse w-full h-5 my-2"></div>
        <div className="bg-slate-300 animate-pulse w-full h-5 my-2"></div>
        <div className="bg-slate-300 animate-pulse w-full h-5 my-2"></div>
      </div>
    </Col>
  );
};

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, Input, Title } from "../../components";

const AddColors = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data, isLoading } = useFETCH(
    id === "add" ? "" : `admin/colors/${id}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "admin/colors" : `admin/colors/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        color: dataOld?.color,
        name: dataOld?.name,
      });
  }, [dataOld]);
  return (
    <div className="">
      <Row>
        <Col
          md={7}
          className="mx-auto border-2 border-Secondary rounded-2xl p-7 !px-4"
        >
          <Title
            title={id === "add" ? "إضافة ألوان" : "تعديل الألوان"}
            className="mb-14 mt-5"
          />
          <Input
            name="name"
            onChange={handleChangeInput}
            placeholder="الاسم"
            value={formData?.name}
            error={error?.name}
          />
          <input
            type="color"
            name="color"
            onChange={handleChangeInput}
            value={formData?.color}
            placeholder="اللون"
          />
          <div className="text-red-600">{error?.color}</div>

          {loading || (id !== "add" && isLoading) ? <Loading /> : ""}
          <Back name="حفظ" onClick={handleSubmitMain} />
        </Col>
      </Row>
    </div>
  );
};

export default AddColors;

import React, { useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import { useFilter } from "../APIs/useMyAPI";
import { Row } from "../Grid-system";
import { TableLoading } from "../../components";

const Pagination = ({ pageCount, isLoading, children }) => {
  const { handlePageClick, searchParams } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = searchParams.get("page")
        ? searchParams.get("page") - 1
        : 0;
    }
  }, [searchParams.get("page")]);
  return (
    <>
      {isLoading ? (
        <Row className="md:w-2/3 md:!mx-auto rounded-3xl overflow-hidden">
          <TableLoading />
          <TableLoading />
          <TableLoading />
          <TableLoading />
          <TableLoading />
        </Row>
      ) : (
        <div>{children}</div>
      )}
      {pageCount < 1 && (
        <p className="font-semibold text-center">لا يوجد بيانات ليتم عرضها</p>
      )}
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
    </>
  );
};

export default Pagination;

import { useContextHook } from "../../Context/ContextOPen";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.png";
import "./SideBar.css";
function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  const sidebar = [
    { name: "الصفحة الرئيسية", link: "/" },
    { name: "السلايدر الرئيسي", link: "/Home" },
    { name: "معلومات عنا", link: "/About-Us" },
    { name: "تواصل معنا", link: "/Contact-Us" },
    { name: "المستخدمين", link: "/Users" },
    { name: "الأقسام", link: "/Section" },
    { name: "الطلبات", link: "/Orders" },
    { name: "الرسائل", link: "/Message" },
    { name: "الألوان", link: "/colors" },
    { name: "طرق التوصيل", link: "/delivery-methods" },
    { name: "طرق الدفع", link: "/payment-methods" },
    { name: "تغيير كلمة السر", link: "/Change-Password" },
  ];
  return (
    <>
      <div
        className={`${
          openMenu
            ? "!w-[350px] max-lg:!w-[300px] max-sm:!w-[250px]"
            : " w-[0px]"
        } ${
          openMenu
            ? "!max-md:translate-x-0 !max-md:w-3/4 -right-0"
            : "!max-md:translate-x-full"
        }  bg-gradient-to-t to-Main from-Secondary sidebar h-[100vh] sideBar max-md:h-[100%] sticky max-md:fixed right-0 top-0 transition-all overflow-y-scroll py-10 z-40 max-md:z-50 `}
      >
        <div className="px-3">
          <ul className="text-white text-center font-semibold space-y-4 max-sm:mt-10">
            <li className="">
              <img
                src={logo}
                alt=""
                className="w-[200px] h-[80px] mx-auto object-contain"
              />
            </li>
            {sidebar.map((e, i) => (
              <li className="" key={i}>
                <NavLink
                  to={e.link}
                  className="text-xl py-2 block rounded-3xl text-black hover:text-Main hover:bg-white transition-all"
                >
                  {e.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        onClick={changeMenu}
        style={{ transition: "0.4s" }}
        className={`${
          openMenu ? "" : "hidden"
        }  max-md:w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-40`}
      />
    </>
  );
}

export default SideBar;

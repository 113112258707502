import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Back, Input, Title } from "../../components";
import Loading from "../../Tools/Loading";
import { Col, Row } from "../../Tools/Grid-system";

function UpdateCount() {
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({});
  const { data, isLoading } = useFETCH(`admin/orders/get/min-price`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/orders/set/min-price`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    setFormData({
      value: dataOld?.value,
    });
  }, [dataOld]);
  return (
    <div>
      <Title title="تعديل الحد الأدنى للطلب" />
      <Col
        md={6}
        className="p-4 justify-center border-2 border-Secondary rounded-2xl mx-auto mt-5"
      >
        <Input
          name="value"
          onChange={handleChangeInput}
          placeholder="الحد الأدنى للطلب"
          value={formData?.value}
        />
      </Col>
      {loading || isLoading ? <Loading /> : ""}
      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
}
export default UpdateCount;

import { Link } from "react-router-dom";
import { useLOGIN } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading";
import { Input, MainButton, PasswordInput } from "../../components";
import Logo from "../../images/logo.png";

const Login = () => {
  const { handleSubmit, error, loading, handleChange } = useLOGIN();
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/login");
  };
  return (
    <div className="fixed w-full h-full bg-white top-0 left-0 z-50 flex items-center justify-center text-center">
      <div className="w-1/2 max-lg:w-[90%] flex flex-col justify-center space-y-6 border border-Main p-10 max-sm:p-2 rounded-2xl">
        <div className="flex justify-center">
          <img src={Logo} alt="" className="w-[300px] mx-auto" />
        </div>
        <Input
          type="name"
          name="username"
          error={error.username}
          onChange={handleChange}
          placeholder="اسم المستخدم"
        />
        <PasswordInput
          name="password"
          error={error.password}
          onChange={handleChange}
        />
        {/* <div className="cursor-pointer">
          <Link to="/login/forget-password" className="underline text-Main">
            forget Password
          </Link>
        </div> */}
        {loading ? <Loading /> : ""}
        <div className="w-fit mx-auto">
          <MainButton
            name="إرسال"
            className="!w-[200px] max-sm:!w-[110px] py-3"
            onClick={handleSubmitMain}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;

import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Search, FilterOrderStatus, Table, Title } from "../../components";
import { useLocation } from "react-router-dom";
import { Col, Row } from "../../Tools/Grid-system";

const Orders = ({ title, show }) => {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(`admin/orders${search}`);
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.image,
      name: e.first_name + " " + e.last_name,
      status: e.state === 0 ? "بالإنتظار" : e.state === 1 ? "اكتملت" : "مرفوضة",
      prise: "$ " + e.total_price,
    };
  });
  return (
    <div>
      <Title title={title || "الطلبات"} />
      <Row className="items-center">
        <Col md={4} xs={6}>
          <Search />
        </Col>
        <Col md={2} xs={3} sm={6}>
          <FilterOrderStatus />
        </Col>
      </Row>
      <Table
        isLoading={isLoading}
        thead={["الرقم", "صورة الطلب", "الاسم", "السعر الكلي"]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        showContent="عرض عناصر الطلب"
        tbody={["id", "image", "name", "prise"]}
        show={show}
        status={true}
        funDelete={deleteItem}
        deleteText="حذف الطلب"
      />
    </div>
  );
};

export default Orders;

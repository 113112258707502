import { useEffect } from "react";
import { useContextHook } from "../../Context/ContextOPen";
import { MdCreditScore, MdError } from "react-icons/md";
function Message() {
  const { message, setMessage } = useContextHook();
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 4000);
    } else {
      setMessage("");
    }
  }, [message]);
  
  return (
    <>
      {
        <div
          className={`fixed z-50 transition-all left-7 border-t-4 ${
            message?.status === 200 || message?.status === 201
              ? "border-green-700"
              : "border-red-700"
          }  shadow-lg z-[999999999] rounded-b-xl overflow-hidden ${
            message?.length !== 0 ? "bottom-10 " : "-bottom-full"
          }`}
          onClick={() => setMessage([])}
        >
          <div
            className={`${
              message?.status === 200 || message?.status === 201
                ? "bg-green-500"
                : "bg-red-500"
            }  text-white font-semibold pb-5 pt-2 px-7`}
          >
            {message?.status === 200 || message?.status === 201 ? (
              <MdCreditScore size={25} className="text-white mx-auto mb-1" />
            ) : (
              <MdError size={25} className="text-white mx-auto mb-1" />
            )}
            {message?.data?.success ? (
              <>
                {message?.status === 200 && (
                  <p className="mb-2 text-white">تم التعديل بنجاح</p>
                )}
                {message?.status === 201 && (
                  <p className="mb-2 text-white">تمت الإضافة بنجاح</p>
                )}
              </>
            ) : (
              <>
                {message?.errors?.map((e, i) => (
                  <div key={i} className="flex items-center">
                    <div className="w-2 h-2 rounded-full bg-white mx-2" />
                    <p className="mb-2">{e}</p>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      }
    </>
  );
}

export default Message;

import { useLocation } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, Table, Title } from "../../components";

const PaymentMethods = () => {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/payment-methods${search}`
  );
  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.image,
      name: e.name?.ar,
      description_ar: e.description?.ar,
    };
  });
  return (
    <div>
      <Title title="طرق الدفع" />
      <Table
        isLoading={isLoading}
        thead={["الرقم", "الصورة", "الاسم", "الوصف"]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={["id", "image", "name", "description_ar"]}
        edit="payment-methods"
        funDelete={deleteItem}
      />
      <Add link="/payment-methods/add" />
    </div>
  );
};

export default PaymentMethods;

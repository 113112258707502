import React from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import Popup from "../Popup/Popup";
const Delete = ({ deleteClick, deleteText }) => {
  return (
    <div>
      <Popup
        text={deleteText || "حذف"}
        id="delete"
        description="هل انت متأكد من حذف هذا العنصر ؟"
        onClick={deleteClick}
      >
        <RiDeleteBin5Line size={30} className="text-red-600" />
      </Popup>
    </div>
  );
};

export default Delete;

import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col } from "../../Tools/Grid-system";
import { Add, Search, Table, Title } from "../../components";

const Users = () => {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/users${search}`,
    "admin/users"
  );
  const usersData = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      username: e.username,
      phone_number: e.phone_number,
      created_at: e.created_at?.substring(0, 10),
    };
  });
  return (
    <div>
      <Title title="المستخدمين" />
      <Col md={4} xs={6}>
        <Search />
      </Col>
      <Table
        isLoading={isLoading}
        thead={["الرقم", "الاسم", "رقم الهاتف", "تاريخ الإنشاء"]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={usersData}
        tbody={["id", "username", "phone_number", "created_at"]}
        edit="users"
        funDelete={deleteItem}
        show="users/orders?user_id="
        deleteText="حذف المستخدم"
        showContent="عرض الطلبات الخاصة بهذا المستخدم"
        updateText="تعديل هذا المستخدم"
      />
      <Add link="/users/add" />
    </div>
  );
};

export default Users;

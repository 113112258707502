import { useFilter } from "../../Tools/APIs/useMyAPI";

function MapSelect({
  text,
  title,
  name,
  value,
  onChange,
  className,
  options,
  valueOption,
  nameOption,
  All,
}) {
  const { handleParamsDelete } = useFilter();

  return (
    <div className={`py-3`}>
      {text && <h1 className={`text-start mb-2`}>{text}</h1>}
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={`w-full border-2 border-Main py-3 rounded-xl text-lg outline-none text-center mx-auto ${className}`}
      >
        <option value="" disabled selected hidden>
          {title}
        </option>
        {All && (
          <option value="">
            الكل
          </option>
        )}
        {Array.isArray(options) &&
          options.map((e, key) => (
            <option key={key} value={e?.[valueOption]}>
              {e?.[nameOption]}
            </option>
          ))}
      </select>
    </div>
  );
}
export default MapSelect;

import React from "react";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { Add, CardBox, Data, Title } from "../../components";

const Colors = () => {
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/colors`,
    "admin/colors"
  );
  return (
    <>
      <Title title="الألوان" />
      {isLoading ? <Loading /> : ""}
      <Row>
        {data?.data.data.map((e) => (
          <Col lg={4} md={6} key={e.id}>
            <CardBox
              showEdit={true}
              deleteClick={() => deleteItem(e)}
              edit={`/colors/${e.id}`}
            >
              <Data load={isLoading} name="الاسم" text={e.name} />
              <div
                style={{ background: e.color }}
                className={`w-4/5 h-8 rounded-lg mx-auto my-2`}
              />
            </CardBox>
          </Col>
        ))}
      </Row>
      <Add link={`/colors/add`} />
    </>
  );
};

export default Colors;
const Loading = () => {
  return (
    <Col lg={4} xs={6}>
      <div className="w-full h-fit rounded-2xl p-5 bg-white border pt-8">
        <div className="bg-slate-300 animate-pulse w-full h-5 my-2"></div>
      </div>
    </Col>
  );
};

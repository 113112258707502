import { usePOST } from "../../Tools/APIs/useMyAPI";
import AddImage from "../../Tools/AddFile";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back } from "../../components";

const AddImageSlider = () => {
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    setViewFile,
    viewFile,
    formData,
    loading,
    error,
  } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/sliders`);
  };
  return (
    <div>
      <Row justify={"center"}>
        <Col md={7} className="">
          <AddImage
            name="image"
            newImage={viewFile}
            onChange={handleChangeInput}
            clickDeleteImage={() => {
              setViewFile("");
              setFormData({ ...formData, images: "" });
            }}
            error={error?.image}
          />
          {loading ? <Loading /> : ""}
        </Col>
      </Row>
      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
};

export default AddImageSlider;

import { BiMenu } from "react-icons/bi";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { logout } from "../../Tools/APIs/useMyAPI";
import { Popup } from "../../components";
import Logo from "../../images/logo.png";
const Navbar = () => {
  const { changeMenu } = useContextHook();
  return (
    <>
      <div className="sticky top-0 z-20 flex items-center justify-between bg-[#E5E5E5] border-b-2 border-black px-8 py-2">
        <div>
          <BiMenu
            size={40}
            className="cursor-pointer text-Main"
            onClick={changeMenu}
          />
        </div>
        <Link to="/">
          <img src={Logo} alt="" className="object-contain w-28" />
        </Link>
        <Popup
          id={`logout`}
          text="تسجيل خروج"
          description="هل انت متأكد من تسجيل الخروج ؟"
          onClick={() => logout("admin/logout")}
        >
          <RiLogoutBoxRLine className="cursor-pointer text-Main" size={30} />
        </Popup>
      </div>
    </>
  );
};

export default Navbar;

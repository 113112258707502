import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import AddImage from "../../Tools/AddFile";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, Input, Title } from "../../components";

const AddSection = () => {
  const { id } = useParams({});
  const {
    handleChangeInput,
    handleSubmit,
    viewFile,
    setViewFile,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data, isLoading } = useFETCH(
    id === "add" ? "" : `admin/sections/${id}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "admin/sections" : `admin/sections/${id}`);
  };
  let dataOld = data?.data.data?.section;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        name_en: dataOld?.name?.en,
        name_ar: dataOld?.name?.ar,
        name_kur: dataOld?.name?.kur,
      });
  }, [dataOld, viewFile]);
  return (
    <div>
      <Title title={id === "add" ? "إضافة قسم" : "تعديل القسم"} />
      <Row className="p-4 border-2 border-Secondary rounded-2xl justify-center">
        <Col md={6}>
          <Input
            name="name_en"
            onChange={handleChangeInput}
            placeholder="الاسم بالإنكليزي"
            value={formData?.name_en}
            error={error?.name_en}
          />
        </Col>
        <Col md={6}>
          <Input
            name="name_ar"
            onChange={handleChangeInput}
            placeholder="الاسم بالعربي"
            value={formData?.name_ar}
            error={error?.name_ar}
          />
        </Col>
        <Col md={6}>
          <Input
            name="name_kur"
            onChange={handleChangeInput}
            placeholder="الاسم بالكردي"
            value={formData?.name_kur}
            error={error?.name_kur}
          />
        </Col>
        <Col md={6}>
          <AddImage
            name="image"
            error={error?.image}
            oldImage={dataOld?.image && fileUrl + dataOld?.image}
            newImage={viewFile}
            onChange={handleChangeInput}
            title="إضف صورة القسم"
            clickDeleteImage={() => {
              setViewFile("");
              setFormData({ ...formData, images: "" });
            }}
          />
        </Col>
      </Row>
      {loading || (id !== "add" && isLoading) ? <Loading /> : ""}
      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
};

export default AddSection;

import { VscWorkspaceTrusted } from "react-icons/vsc";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import Popup from "../Popup/Popup";
function Accept({ id }) {
  const { handleSubmit } = usePOST({});
  const handleClick = () => {
    handleSubmit(`admin/orders/${id}/state?state=` + 1, "", "", true);
  };
  return (
    <div>
      <Popup
        text="قبول الطلب"
        id="Accept"
        description="هل انت متأكد من قبول هذا الطلب ؟"
        onClick={handleClick}
        desClass="text-green-500"
      >
        <VscWorkspaceTrusted className="text-green-500" size={25} />
      </Popup>
    </div>
  );
}
export default Accept;
